import React, {useEffect, useState} from 'react';
import './App.css';
import {LoginForm} from "./LoginForm";

function App() {
    const [redirectTo, setRedirectTo] = useState<string>('')
    const [hash, setHash] = useState<string>('')
    useEffect(() => {
        const {search, hash} = window.location;
        const params = new URLSearchParams(search);
        const redirect = params.get('redirect_to');
        if (redirect) {
            setRedirectTo(decodeURIComponent(redirect));
            setHash(hash)
        }
    }, []);

    return (
        <div className="App">
            <LoginForm redirectTo={redirectTo} hash={hash}/>
        </div>
    );
}

export default App;
